import React from "react";
import { Grid, Typography, Box, Card, CardMedia } from "@mui/material";
import { MdLocationOn } from "react-icons/md";
import { BsFileEarmarkPdf } from "react-icons/bs";

import useStyles from "../views/components/Auction/AuctionDetail/auctionDetail.style";
import moment from "moment";
import momentTimeZone from "moment-timezone";

const Mount = React.memo(({ condition, children }) => {
  if (condition === true) {
    return children;
  }
  return <React.Fragment></React.Fragment>;
});

const AuctionDetails = React.memo((props) => {
  const classes = useStyles();
  const getZone =
    localStorage.getItem("currentTimeZone") || momentTimeZone.tz.guess();
  const eventsDetails = props.eventsDetails ?? {};

  const renderAuctionTypeFormat = (eventType, eventFormat) => {
    if (!eventType || !eventFormat) return null;
    const auctionType = eventType.split("_")[0]?.toLowerCase();

    const auctionFormat = eventFormat.split("_")[0]?.toLowerCase();

    return (
      <Typography
        component={"div"}
        className={classes.Upcoming}
        style={{
          // on small screen it will be 100%
          width: "100%",
          display: "flex",
        }}
      >
        <Typography
          component={"span"}
          style={{
            fontWeight: "bold",
            textTransform: "capitalize",
          }}
        >
          {auctionFormat}
        </Typography>
        <Typography
          component={"span"}
          className={classes.auctionFormat}
          style={{
            fontWeight: "bold",
            marginLeft: ".4rem",
          }}
        >
          {auctionType} Auction
        </Typography>
      </Typography>
    );
  };

  return (
    <Grid className={classes.upperAuction}>
      <Grid container spacing={2} style={{ padding: "25px 0px 0px 4px" }}>
        <Grid item xs={12} md={3}>
          <Card elevation={3} style={{ height: "100%" }}>
            <CardMedia
              image={eventsDetails.eventImageUrl}
              component="img"
              alt="product"
              style={{ objectFit: "cover", height: "11em" }}
              height="100%"
              className="mainCardimg"
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Typography
            style={{ marginBottom: "0 !important" }}
            paragraph
            className="firstText"
          >
            <Mount condition={eventsDetails.status === "live"}>
              <span className={classes.liveButton}>
                Live Now -{" "}
                {moment(eventsDetails.bidStartDateTime)
                  .tz(getZone)
                  .format("DD-MM-YYYY hh:mm:ss")}
              </span>
              <br />
            </Mount>
            <Mount condition={eventsDetails.status === "ended"}>
              <span className={`${classes.ended}`}>Ended</span>
              <br />
              <br />
            </Mount>
            <Mount condition={eventsDetails.status === "upcoming"}>
              <span
                className={classes.Upcoming}
                style={{
                  color: "#f0b70e",
                  border: "1px solid #f0b70e",
                }}
              >
                Upcoming -{" "}
                {moment(eventsDetails.bidStartDateTime)
                  .tz(getZone)
                  .format("DD-MM-YYYY hh:mm:ss")}
              </span>
              <br />
            </Mount>
            <Mount condition={eventsDetails.previewPeriod !== "NO"}>
              <span className={classes.Upcoming}>
                Prebid Period -{" "}
                {moment(eventsDetails.publishDateTime)
                  .tz(getZone)
                  .format("DD-MM-YYYY hh:mm:ss")}
              </span>
            </Mount>
          </Typography>

          <Grid item xs={12} md={4}>
            {renderAuctionTypeFormat(
              eventsDetails.eventType,
              eventsDetails.eventFormat
            )}
          </Grid>

          <Typography className={classes.auctionHeading} component={"span"}>
            {eventsDetails.auctionTitle}, {eventsDetails.referenceNumber}
          </Typography>
          <a href="/">
            <p className={classes.auctionLink}>
              {eventsDetails.auctioneerName}
            </p>
          </a>
          <Typography className={classes.location}>
            <MdLocationOn style={{ fontSize: "25px" }} />{" "}
            {eventsDetails.streetOne}, {eventsDetails.streetTwo},{" "}
            {eventsDetails.city},{eventsDetails.province},{" "}
            {eventsDetails.postalCode}, {eventsDetails.country}
          </Typography>
        </Grid>
        <Grid item xs={12} md={1}>
          <a
            aria-label="open drawer"
            style={{ padding: "0px" }}
            href={eventsDetails.auctionCatalogueUrl}
            target="_Blank"
            color="inherit"
            className="btn btn-group catelog"
          >
            <BsFileEarmarkPdf
              style={{ fontSize: "30px", color: "red", paddingTop: "4px" }}
            />
            <h5>Catalog</h5>
          </a>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box
            sx={{
              backgroundColor: "#c4d1ff",
              color: "#0d1d58",
              margin: "1em 0",
              paddingTop: "5px",
            }}
          >
            <marquee> {eventsDetails.eventMessage}</marquee>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default AuctionDetails;
