import React, { useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import CreateIcon from "@mui/icons-material/Create";
import { Button } from "@mui/material";
import useStyles from "./createEvent.styles";

const AuctionTypeSelectorDropdown = ({ onSelect }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const selectOptionCall = (option) => {
    onSelect(option);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <Typography
        variant="body1"
        onClick={handleClick}
        sx={{
          cursor: "pointer",
          fontWeight: "bold",
          color: "inherit",
        }}
      >
        <Button
          variant="contained"
          component="label"
          className={classes.upload}
          sx={{
            display: "block",
            textAlign: "right",
          }}
          size="small"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            textTransform: "none",
            width: "16rem",
            marginBottom: ".6rem",
          }}
        >
          <CreateIcon
            className={classes.uploadIcon}
            style={{
              marginRight: "8px",
              fontSize: "22px",
            }}
          />
          <span className="text-black normal-case">
            Create auction manually
          </span>
        </Button>
      </Typography>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            width: anchorEl ? anchorEl.offsetWidth : "16rem", // Match the button width
          },
        }}
      >
        <MenuItem onClick={() => selectOptionCall("english_auction")}>
          English Auction
        </MenuItem>
        <MenuItem onClick={() => selectOptionCall("japanese_auction")}>
          Japanese Auction
        </MenuItem>
        <MenuItem onClick={() => selectOptionCall("dutch_auction")}>
          Dutch Auction
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default AuctionTypeSelectorDropdown;
