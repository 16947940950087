import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import lodash from "lodash";
import momentTimeZone from "moment-timezone";

import { useParams } from "react-router-dom";
import useStyles from "../components/Auction/auction.style";

import getEventDetails from "../../api/getEventDetail";
import getEventItemsListAPI from "../../api/getEventItemsListAPI";
import getBidStatusAPI from "../../api/getBidStatusAPI";
import getMyLotsBuyerAPI from "../../api/getMyLotsBuyerAPI";
import AuctionDetails from "../../packages/auction-details";
import AuctionLotsContainer from "../../packages/auction-lots-container";
import AuctionFilter from "../components/Auction/AuctionFilter";

import io from "socket.io-client";
import { getToken } from "../../common";
import config from "../../config/config";
import { ChevronRight } from "@mui/icons-material";

const Index = () => {
  const location = useParams();
  const auctionId = location.source;
  const classes = useStyles();
  const component = useRef(true);
  const timeZone =
    localStorage.getItem("currentTimeZone") || momentTimeZone.tz.guess();

  const [mobileOpen, setMobileOpen] = useState(true);
  const [socket, setSocket] = useState(null);
  const [settingUpSocket, setSettingUpSocket] = useState(false);
  const [fetchingInProgress, setFetchingInProgress] = useState(false);
  const SOCKET_URL = config.api.socket_url;

  const [details, updateDetails] = useState({});
  const [httpStatus, updateHttpStatus] = useState({
    auctionLots: false,
    auctionBuyerLots: false,
  });
  const [auctionAllLots, updateAuctionAllLots] = useState([]);
  const [auctionUserLots, updateAuctionUserLots] = useState([]);

  const userData = (() => {
    if (!localStorage.getItem("userData")) {
      return {};
    }
    return JSON.parse(localStorage.getItem("userData"));
  })();

  useEffect(() => {
    httpRequest();
  }, []);

  useEffect(() => {
    setSocketListener();
    return () => {
      if (socket) {
        socket.disconnect();
        console.log("Socket Disconnected");
      }
    };
  }, []);

  useEffect(() => {
    if (!socket) return;

    if (userData) {
      socket.on(`${userData.userId}`, (props) => {
        socketListener(props);
      });
    } else if (details?.eventId) {
      socket.on(`${details?.eventId}`, (props) => {
        socketListener(props);
      });
    }

    return () => {
      if (userData) {
        socket.off(`${userData.userId}`, (props) => {
          console.log("Socket Disconnected", props);
        });
      } else if (details?.eventId) {
        socket.off(`${details?.eventId}`, (props) => {
          console.log("Socket Disconnected", props);
        });
      }
    };
  }, [userData, socket, details?.eventId]);

  const setSocketListener = () => {
    if (!socket && !settingUpSocket) {
      setSettingUpSocket(true);
      const newSocket = io(SOCKET_URL, {
        extraHeaders: { authorization: getToken() },
      });
      setSocket(newSocket);
      setSettingUpSocket(false);
      console.log("Socket Connected");
    }
  };

  const updateListWithBidStatusAllotedLots = (auctionAllotedLots, listData) => {
    return lodash.map(listData, (details) => {
      const searchAuctionLot = lodash.find(
        auctionAllotedLots,
        ({ eventItemId }) => eventItemId === details.eventItemId
      );
      if (lodash.isUndefined(searchAuctionLot)) {
        details.myBidStatus = {
          myLastBid: 0,
          myBidRank: "0",
          status: "error",
          authorized: false,
        };
      } else {
        details.myBidStatus = searchAuctionLot.myBidStatus;
      }

      return details;
    });
  };

  const updateListWithDefaultBidStatus = (listData) =>
    listData.map((details) => ({
      ...details,
      myBidStatus: {
        myLastBid: 0,
        myBidRank: "0",
        status: "error",
        authorized: false,
      },
    }));

  const updateListWithBidStatus = async (listData) =>
    Promise.all(
      listData.map(async (object) => {
        try {
          const { data } = await getBidStatusAPI(
            object.eventId,
            object.eventItemId,
            { timeZone }
          );
          return { ...object, myBidStatus: data };
        } catch (error) {
          return {
            ...object,
            myBidStatus: {
              myLastBid: 0,
              myBidRank: "0",
              status: "error",
              authorized: true,
            },
          };
        }
      })
    );

  const httpRequest = async () => {
    try {
      if (fetchingInProgress) return;
      setFetchingInProgress(true);
      const data = { auctionLots: [], auctionBuyerLots: [] };
      const detailsResponse = await getEventDetails(
        {
          auctionId: location.source,
        },
        { timeZone }
      );
      const listResponse = await getEventItemsListAPI(
        {
          itemId: location.source,
        },
        { timeZone }
      );

      if (detailsResponse.status === 200) {
        updateDetails({ ...detailsResponse.data });
      }

      if (listResponse.status === 200) {
        if (lodash.isEmpty(userData)) {
          data.auctionLots = updateListWithDefaultBidStatus(
            listResponse.data.data
          );
        } else {
          const { status, data: response } = await getMyLotsBuyerAPI(
            {
              eventId: location.source,
            },
            { timeZone }
          );

          if (status !== 200) {
            data.auctionLots = updateListWithDefaultBidStatus(
              listResponse.data.data
            );
          } else {
            data.auctionBuyerLots = await updateListWithBidStatus(
              response.data ?? []
            );
            data.auctionLots = updateListWithBidStatusAllotedLots(
              data.auctionBuyerLots,
              listResponse.data.data
            );
          }
        }
        updateAuctionAllLots([...data.auctionLots]);
        updateAuctionUserLots([...data.auctionBuyerLots]);
        updateHttpStatus({ auctionLots: true, auctionBuyerLots: true });
        if (!socket) {
          setSocketListener();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingInProgress(false);
    }

    return true;
  };

  const handleEnglishAuctionSocket = (props) => {
    const updateSearchedAllLots = lodash.map(
      lodash.cloneDeep([...auctionAllLots]),
      (details) => {
        if (details.eventItemId !== props.eventItemId) {
          return details;
        }

        details.myBidStatus = props;
        details.highestBid = props.highestBid;
        details.nextBidAmount = props.nextBidAmount;
        details.endDateAndTime = props.updatedEndTime;

        return { ...details };
      }
    );

    const updateSearchedUserLots = lodash.map(
      lodash.cloneDeep([...auctionUserLots]),
      (details) => {
        if (details.eventItemId !== props.eventItemId) {
          return details;
        }

        details.myBidStatus = props;
        details.highestBid = props.highestBid;
        details.nextBidAmount = props.nextBidAmount;
        details.endDateAndTime = props.updatedEndTime;

        return { ...details };
      }
    );

    updateAuctionAllLots([...updateSearchedAllLots]);
    updateAuctionUserLots([...updateSearchedUserLots]);
  };

  const handleDutchAuctionSocket = (props) => {
    const receivedLots = props?.data || [];

    const structuredReceivedLots = {};

    receivedLots.forEach((lot) => {
      structuredReceivedLots[lot.eventItemId] = {
        ...lot,
      };
    });

    const updatedAllLots = auctionAllLots
      .map((details) => {
        return {
          ...details,
          ...(structuredReceivedLots[details.eventItemId] || {}),
        };
      })
      .filter((lot) => lot.concluded !== "YES");

    const updatedUserLots = auctionUserLots
      .map((details) => {
        return {
          ...details,
          ...(structuredReceivedLots[details.eventItemId] || {}),
        };
      })
      .filter((lot) => lot.concluded !== "YES");

    updateAuctionAllLots(updatedAllLots);
    updateAuctionUserLots(updatedUserLots);
  };

  const handleJapaneseAuctionSocket = (props) => {
    const receivedLots = props?.data || [];

    const structuredReceivedLots = {};

    receivedLots.forEach((lot) => {
      structuredReceivedLots[lot.eventItemId] = {
        ...lot,
      };
    });

    const updatedAllLots = auctionAllLots.map((details) => {
      return {
        ...details,
        ...(structuredReceivedLots[details.eventItemId] || {}),
      };
    });

    const updatedUserLots = auctionUserLots.map((details) => {
      return {
        ...details,
        ...(structuredReceivedLots[details.eventItemId] || {}),
      };
    });

    updateAuctionAllLots(updatedAllLots);
    updateAuctionUserLots(updatedUserLots);
  };

  const socketListener = (props) => {
    try {
      if (httpStatus.auctionLots === false) {
        return () => null;
      }

      if (!props) return;

      switch (details?.eventFormat) {
        case "ENGLISH_AUCTION":
          handleEnglishAuctionSocket(props);
          break;
        case "DUTCH_AUCTION":
          handleDutchAuctionSocket(props);
          break;
        case "JAPANESE_AUCTION":
          handleJapaneseAuctionSocket(props);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  // filter lot list and remove the lot which has been ended
  const removeEndedLot = (id) => {
    const updatedAllLots = auctionAllLots?.filter(
      (lot) => lot.eventItemId != id
    );

    const updatedUserLots = auctionUserLots?.filter(
      (lot) => lot.eventItemId != id
    );

    updateAuctionAllLots(updatedAllLots);
    updateAuctionUserLots(updatedUserLots);
  };

  // update lot list & add finalize flag
  const finalizeLot = (id) => {
    const updatedAllLots = auctionAllLots?.map((lot) => {
      if (lot.eventItemId != id) return lot;
      else {
        return {
          ...lot,
          finalizing: true,
        };
      }
    });

    const updatedUserLots = auctionUserLots?.map((lot) => {
      if (lot.eventItemId != id) return lot;
      else {
        return {
          ...lot,
          finalizing: true,
        };
      }
    });

    updateAuctionAllLots(updatedAllLots);
    updateAuctionUserLots(updatedUserLots);

    setTimeout(() => {
      // remove lot
      removeEndedLot(id);
    }, 5000);
  };

  return (
    <div className={classes.auction}>
      {mobileOpen ? (
        <Box className={classes.auctionFilter} style={{ width: "25%" }}>
          <AuctionFilter
            filterChange={true}
            setEventIdFilter={() => {}}
            eventIdFilter={[]}
            setCountryId={1}
            setStateId={1}
            auctionIds={[]}
            setAuctionId={() => {}}
            setMobileOpen={setMobileOpen}
          />
        </Box>
      ) : (
        <div
          style={{
            background: "#2D55EB",
            height: "3rem",
            marginTop: "2rem",
            zIndex: "2",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            padding: ".1rem",
            borderTopRightRadius: "2rem",
            borderBottomRightRadius: "2rem",
            transition: "all ease-in-out .3s",
            cursor: "pointer",
          }}
          onClick={() => setMobileOpen(true)}
        >
          <ChevronRight
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              color: "white",
            }}
          />
        </div>
      )}
      <Box className={classes.auctionDetail}>
        <Box className="container">
          <AuctionDetails eventsDetails={details}></AuctionDetails>
          <AuctionLotsContainer
            allLots={auctionAllLots}
            userLots={auctionUserLots}
            eventsDetails={details}
            refetchLotDetails={httpRequest}
            finalizeLot={finalizeLot}
            auctionId={auctionId}
          />
        </Box>
      </Box>
    </div>
  );
};

export default Index;
