import React, { useContext } from "react";
import moment from "moment";
import lodash from "lodash";
import momentTimeZone from "moment-timezone";

import { Dialog, DialogContent } from "@mui/material";
import { Grid, Box, Typography, Button, Card, CardMedia } from "@mui/material";
import { MdSignalWifiStatusbarConnectedNoInternet as WifiSVG } from "react-icons/md";

import { Mount, MountCondition } from "./mui-methods.jsx";
import useStyles from "./mui-component-style";
import Countdown from "react-countdown";
import Carousel from "react-bootstrap/Carousel";
import tempImg from "../assets/temp-img.png";
import { toast } from "react-toastify";
import saveBidAPI from "../api/saveBidAPI.js";

import { TimerContext } from "../app/App.js";

const AuctionLotDialog = React.memo((props) => {
  const classes = useStyles();
  const [countdownEndTime, setCountdownEndTime] = React.useState("");
  const eventDetails = props.dialogProps.eventDetails ?? {};
  const lotDetails = props.dialogProps.lotDetails ?? {};
  const userAmount = props.dialogProps.userAmount ?? 0;
  const eventFormat = eventDetails.eventFormat;
  const isJapaneseAuction = eventFormat === "JAPANESE_AUCTION";
  const isDutchAuction = eventFormat === "DUTCH_AUCTION";
  const isEnglishAuction = eventFormat === "ENGLISH_AUCTION";

  const timeZone =
    localStorage.getItem("currentTimeZone") || momentTimeZone.tz.guess();
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);
  const timerContext = useContext(TimerContext);

  const currentTime = moment().tz(timeZone).format();
  const [state, toggleState] = React.useState({
    disabled: false,
    loading: false,
  });
  const startDateAndTime = moment(lotDetails.startDateAndTime)
    .tz(timeZone)
    .format(); // lot start date

  const updateFromTime = () => {
    const fromTime =
      eventDetails.lastUpdatedAmountAt || eventDetails.startDateAndTime;
    const countdownEndTime = moment(fromTime)
      .add(eventDetails.timeInterval, "minutes")
      .tz(timeZone)
      .format();

    setCountdownEndTime(countdownEndTime);
  };

  React.useEffect(() => {
    updateFromTime();
  }, []);

  React.useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  const handleAuctionBid = async () => {
    try {
      const apiProps = [
        { amount: Number(Number(userAmount).toFixed(2)) },
        lotDetails.eventId,
        lotDetails.eventItemId,
      ];
      await Promise.resolve(toggleState({ disabled: true, loading: true }));
      const response = await saveBidAPI(...apiProps);

      if (response.data.status === "FAILURE") {
        if (response.data.reason) {
          toast.error(response.data.reason);
        }
        toast.error(
          `Your Bid Has Been Recorded, but Unfortunately, It Was Not Accepted.`
        );
      } else {
        toast.success("Your Bid Submitted Successfully!");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    } finally {
      await Promise.resolve(toggleState({ disabled: false, loading: false }));
    }

    return props.handleAuctionDialog({});
  };

  if (lodash.isEmpty(lotDetails)) {
    return <></>;
  }

  return (
    <Dialog
      open={props.activeDialog}
      onClose={(...props) => {
        console.log(props);
      }}
      fullWidth={true}
      maxWidth="lg"
    >
      <Mount condition={state.loading}>
        <div className="loaderBg">
          <div className="loader"></div>
        </div>
      </Mount>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xs={12} md={3} style={{ backgroundColor: "transparent" }}>
            <MountCondition
              condition={lodash.isEmpty(lotDetails.eventItemImagesUrl)}
            >
              <MountCondition.True>
                <Card elevation={1} style={{ height: "200px !important" }}>
                  <CardMedia
                    className={classes.carouselImage}
                    image={tempImg}
                    component="img"
                    alt="product"
                    height="100%"
                  />
                </Card>
              </MountCondition.True>
              <MountCondition.False>
                <Carousel>
                  {lotDetails.eventItemImagesUrl.map(
                    (eventItemImages, index) => (
                      <Carousel.Item key={index}>
                        <Card
                          elevation={1}
                          style={{ height: "200px !important" }}
                        >
                          <CardMedia
                            className={classes.carouselImage}
                            image={eventItemImages}
                            component="img"
                            alt="product"
                            height="100%"
                          />
                        </Card>
                      </Carousel.Item>
                    )
                  )}
                </Carousel>
              </MountCondition.False>
            </MountCondition>
          </Grid>
          <Grid className={classes.mainDetail} item xs={12} md={9}>
            <Grid container spacing={0}>
              <Grid className={classes.auctionDetails}>
                <Grid
                  item
                  xs={4}
                  md={3}
                  style={{
                    backgroundColor: "transparent",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    borderRight: "1px solid #ccc",
                    textAlign: "center",
                  }}
                >
                  <Typography className={classes.lotNumber}>LOT</Typography>
                  <Typography className={classes.ellipsis}>
                    {lotDetails.lotNumber}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={8}
                  md={9}
                  style={{
                    backgroundColor: "transparent",
                    padding: "0px",
                    borderRight: "none !important",
                  }}
                >
                  <Typography className={classes.shortDescription}>
                    {lotDetails.shortDescription}
                  </Typography>
                  <Typography className={classes.longDescription}>
                    {lotDetails.longDescription}
                  </Typography>
                </Grid>
              </Grid>

              {isEnglishAuction && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={7}
                    className={classes.leadBidContainer}
                  >
                    <Box className={classes.leadBidBox}>
                      <Typography className={classes.leadBidText}>
                        Lead Bid &nbsp;
                        <MountCondition
                          condition={eventDetails.previewPeriod !== "NO"}
                        >
                          <MountCondition.True>
                            {/* <Mount condition={startDateAndTime > timerContext.currentTime}>
                          <span>XXX</span>
                        </Mount> */}
                            <Mount condition={startDateAndTime > currentTime}>
                              <span>XXX</span>
                            </Mount>
                            {/* <Mount
                          condition={
                            timerContext.currentTime > startDateAndTime &&
                            eventDetails.showH1L1Bid === "YES"
                          }
                        >
                          {lotDetails.currency} {lotDetails.highestBid}{" "}
                        </Mount> */}
                            <Mount
                              condition={
                                currentTime > startDateAndTime &&
                                eventDetails.showH1L1Bid === "YES"
                              }
                            >
                              {lotDetails.currency} {lotDetails.highestBid}{" "}
                            </Mount>
                          </MountCondition.True>
                          <MountCondition.False>
                            <Mount
                              condition={eventDetails.showH1L1Bid === "YES"}
                            >
                              {lotDetails.currency} {lotDetails.highestBid}{" "}
                            </Mount>
                          </MountCondition.False>
                        </MountCondition>
                      </Typography>
                      <Typography className={classes.leadBidText}>
                        Per {lotDetails.unit}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={5}
                    className={classes.userRankContainer}
                  >
                    <Box
                      className={classes.userRankBox}
                      style={{
                        border:
                          eventDetails.previewPeriod !== "NO" &&
                          moment(lotDetails.startDateAndTime)
                            .tz(timeZone)
                            .format() > moment().tz(timeZone).format()
                            ? "1px solid #db1c1c"
                            : eventDetails.showH1L1Bid === "IN_TOP_RANKS_ONLY"
                            ? lotDetails.myBidStatus.myBidRank >= 1 &&
                              lotDetails.myBidStatus.myBidRank <= 3
                              ? "1px solid #329E0C"
                              : "1px solid #db1c1c"
                            : lotDetails.myBidStatus.myBidRank == 0 ||
                              lotDetails.myBidStatus.myBidRank > 1
                            ? "1px solid #db1c1c"
                            : "1px solid #329E0C",

                        backgroundColor:
                          eventDetails.previewPeriod !== "NO" &&
                          moment(lotDetails.startDateAndTime)
                            .tz(timeZone)
                            .format() > moment().tz(timeZone).format()
                            ? "#fabcbc"
                            : eventDetails.showH1L1Bid === "IN_TOP_RANKS_ONLY"
                            ? lotDetails.myBidStatus.myBidRank >= 1 &&
                              lotDetails.myBidStatus.myBidRank <= 3
                              ? "#E3FFD9"
                              : "#fabcbc"
                            : lotDetails.myBidStatus.myBidRank == 0 ||
                              lotDetails.myBidStatus.myBidRank > 1
                            ? "#fabcbc"
                            : "#E3FFD9",
                      }}
                    >
                      <MountCondition
                        condition={
                          eventDetails.showH1L1Bid === "IN_TOP_RANKS_ONLY"
                        }
                      >
                        <MountCondition.True>
                          {lotDetails.myBidStatus.myBidRank >= 1 &&
                          lotDetails.myBidStatus.myBidRank <= 3 ? (
                            <Typography component="span">
                              Your bid is among top 3 bids for this lot.
                            </Typography>
                          ) : (
                            <Typography component="span">
                              Your bid is not among top 3 bids for this lot.
                            </Typography>
                          )}
                        </MountCondition.True>
                        <MountCondition.False>
                          <MountCondition
                            condition={
                              eventDetails.previewPeriod !== "NO" &&
                              moment(lotDetails.startDateAndTime)
                                .tz(timeZone)
                                .format() > moment().tz(timeZone).format()
                            }
                          >
                            <MountCondition.True>
                              <Typography component="span">
                                Your Rank{" "}
                              </Typography>
                              <Typography component="span">N/A</Typography>
                            </MountCondition.True>
                            <MountCondition.False>
                              <MountCondition
                                condition={
                                  lotDetails.myBidStatus.myBidRank == 0
                                }
                              >
                                <MountCondition.True>
                                  <Typography component="span">
                                    Your Rank{" "}
                                  </Typography>
                                  <Typography component="span">N/A</Typography>
                                </MountCondition.True>
                                <MountCondition.False>
                                  <Typography component="span">
                                    Your Rank{" "}
                                  </Typography>
                                  <Typography component="span">
                                    {eventDetails.eventType ===
                                    "FORWARD_AUCTION"
                                      ? "H"
                                      : "L"}
                                    {lotDetails.myBidStatus.myBidRank}
                                  </Typography>
                                </MountCondition.False>
                              </MountCondition>
                            </MountCondition.False>
                          </MountCondition>
                        </MountCondition.False>
                      </MountCondition>
                    </Box>
                  </Grid>
                </>
              )}
              <Grid
                item
                xs={12}
                md={7}
                className={classes.amountSecondContainer}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography className={classes.amountSecondHeading}>
                    {lotDetails.status === "live"
                      ? "Bid Ends (D:H:M:S):"
                      : "Bid Starts (D:H:M:S):"}
                  </Typography>
                  <Typography
                    className={
                      lotDetails.status === "live"
                        ? classes.amountSecondValueLive
                        : classes.amountSecondValueUpcoming
                    }
                  >
                    <Countdown
                      className={classes.bidLive}
                      isPlaying={true}
                      // onComplete={() => {
                      //   // close the dialog -- time to bid at this price is over
                      //   toast.warn("Time to bid at this price is over.");
                      //   // props.handleAuctionDialog({});
                      // }}
                      date={moment(
                        lotDetails.status === "live"
                          ? !isEnglishAuction
                            ? countdownEndTime
                            : lotDetails.endDateAndTime
                          : lotDetails.startDateAndTime
                      )
                        .tz(timeZone)
                        .format()}
                    />
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography className={classes.amountSecondHeading}>
                    Quantity:
                  </Typography>
                  <Typography
                    className={
                      lotDetails.status === "live"
                        ? classes.amountSecondValueLive
                        : classes.amountSecondValueUpcoming
                    }
                  >
                    {lotDetails.quantity} {lotDetails.unit}
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                md={5}
                className={classes.amountThirdContainer}
              >
                <Box className={classes.amountThirdContainerBox}>
                  <Typography className={classes.amountThirdTextStyle}>
                    {moment(
                      lotDetails.status === "live"
                        ? lotDetails.endDateAndTime
                        : lotDetails.startDateAndTime
                    )
                      .tz(timeZone)
                      .format("DD-MM-YYYY hh:mm:ss")}
                  </Typography>
                  <Typography className={classes.amountThirdTextStyle}>
                    <Mount condition={eventDetails.previewPeriod !== "NO"}>
                      <Mount
                        condition={
                          moment(lotDetails.startDateAndTime)
                            .tz(timeZone)
                            .format() > moment().tz(timeZone).format()
                        }
                      >
                        <span className={classes.biddingDate}>
                          Amount : XXX
                        </span>
                      </Mount>
                      <Mount
                        condition={
                          moment().tz(timeZone).format() >
                          moment(lotDetails.startDateAndTime)
                            .tz(timeZone)
                            .format()
                        }
                      >
                        <Mount condition={eventDetails.showH1L1Bid === "YES"}>
                          <span className={classes.biddingDate}>
                            Amount : {lotDetails.quantity * userAmount}
                          </span>
                        </Mount>
                      </Mount>
                    </Mount>

                    <Mount
                      condition={
                        eventDetails.showH1L1Bid === "YES" &&
                        eventDetails.previewPeriod === "NO"
                      }
                    >
                      <span className={classes.biddingDate}>
                        Amount : {lotDetails.quantity * userAmount}
                      </span>
                    </Mount>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={12} md={3} className={classes.amountFourthContainer}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography className={classes.amountFourthTextStyle}>
                Start Price
              </Typography>
              <Typography className={classes.amountFourthTextStyle}>
                {eventDetails.currency} {lotDetails.startPrice}
              </Typography>
            </Box>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography className={classes.amountFourthTextStyle}>
                {eventDetails.eventType === "FORWARD_AUCTION"
                  ? "Increment"
                  : "Decrement"}
              </Typography>
              <Typography className={classes.amountFourthTextStyle}>
                {eventDetails.currency} {lotDetails.increment}
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            className={classes.auctionDialogAmountContainer}
          >
            <Box className={classes.auctionDialogAmountData}>
              {lotDetails.currency} {userAmount}
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            className={classes.auctionBidContainer}
          >
            <MountCondition condition={isOnline}>
              <MountCondition.True>
                <Button
                  onClick={() => {
                    handleAuctionBid();
                  }}
                  className={classes.auctionBidButton}
                  disabled={state.disabled}
                >
                  Confirm
                </Button>
              </MountCondition.True>
              <MountCondition.False>
                <Box className={classes.auctionBidErrorBox}>
                  Network Lost{" "}
                  <WifiSVG style={{ marginLeft: "5px", fontSize: "24px" }} />
                </Box>
              </MountCondition.False>
            </MountCondition>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            className={classes.auctionDialogCancelContainer}
          >
            <Button
              onClick={() => {
                props.handleAuctionDialog({});
              }}
              className={classes.auctionDialogCancelButton}
              disabled={state.disabled}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
});

export default AuctionLotDialog;
