import React, { useEffect, useState } from "react";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import Countdown from "react-countdown";
import {
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { MdSignalWifiStatusbarConnectedNoInternet as WifiSVG } from "react-icons/md";

import MyBidRankComponent from "./BidRankComponent";
import CountdownRenderer from "./CountdownRenderer";
import { getUserData } from "../../../../common";

const capitalize = (str) => {
  const capitalizedWords = str
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
  return capitalizedWords.join(" ");
};

const getLocationString = (event, location) => {
  if (location) return location;
  if (event?.city && event?.country) return `${event.city}, ${event.country}`;
  if (event?.city) return `${event?.city}`;
  if (event?.country) return `${event?.country}`;
  return "";
};

const createBidListLots = ({
  lots,
  styles,
  userAmount,
  eventDetails,
  handleUserAmountChange,
  storeHandler,
  isForwardAuction,
  isOnline,
  lotBidEnded,
  refreshPanelDetails,
}) => {
  const eventFormat = eventDetails.eventFormat;
  const timeZone = momentTimeZone.tz.guess();
  const userData = getUserData();
  let currentTime = moment().tz(timeZone).format();

  const isEnglishAuction = eventFormat === "ENGLISH_AUCTION";
  const isJapaneseAuction = eventFormat === "JAPANESE_AUCTION";
  const isDutchAuction = eventFormat === "DUTCH_AUCTION";

  const columns = [
    {
      name: "Lot No.",
      width: "90px",
      selector: (data) => capitalize(String(data.lotNumber)),
      sortable: true,
    },
    {
      name: "Short Description",
      width: "140px",
      selector: (data) => (
        <Tooltip title={String(data.shortDescription)}>
          {String(data.shortDescription)}
        </Tooltip>
      ),
      sortable: false,
    },
    {
      name: "Location",
      width: "100px",
      selector: (data) => (
        <Tooltip title={String(data.location)}>{String(data.location)}</Tooltip>
      ),
      sortable: true,
    },
    {
      name: "Qty & Unit",
      width: "100px",
      selector: (data) =>
        String(`${Number(data.quantity)} ${data.unit.toUpperCase()}`),
      sortable: false,
    },
    {
      name: "Start Price",
      width: "110px",
      selector: (data) => String(Number(data.startPrice)),
      sortable: true,
    },
    ...(isJapaneseAuction || isDutchAuction
      ? [
          {
            name: "Current Price",
            width: "130px",
            selector: (data) => String(parseFloat(data.currentPrice)),
            sortable: true,
          },
          {
            name: "Time Remaining",
            width: "150px",
            selector: (data, index) => {
              const fromTime =
                data.lastUpdatedAmountAt || data.startDateAndTime;
              const countdownEndTime = moment(fromTime)
                .add(data.timeInterval, "minutes")
                .tz(timeZone)
                .format();

              return (
                <Typography
                  className={
                    data.status === "live"
                      ? styles.amountSecondValueLive
                      : styles.amountSecondValueUpcoming
                  }
                >
                  <Countdown
                    key={`countdown-${
                      data.lastUpdatedAmountAt || data.id || index
                    }`} // Use a stable key
                    className={styles.bidLive}
                    date={countdownEndTime}
                    onComplete={() => {
                      refreshPanelDetails();
                    }}
                    renderer={(...args) =>
                      CountdownRenderer(data.finalizing, ...args)
                    }
                  />
                </Typography>
              );
            },
          },
        ]
      : []),
    {
      name: `${isForwardAuction ? "INCR" : "DECR"} (${eventDetails.currency})`,
      width: "112px",
      selector: (data) => `${Number(data.increment)}`,
      sortable: true,
    },
    ...(isJapaneseAuction
      ? [
          {
            name: `Lead Bid (${eventDetails.currency})`,
            width: "134px",
            selector: (data) => {
              const startDateAndTime = moment(data.startDateAndTime);
              currentTime = moment(currentTime);

              if (startDateAndTime.isAfter(currentTime)) {
                return <span>-</span>;
              } else if (currentTime.isSameOrAfter(startDateAndTime)) {
                return `${
                  data.highestBid === null || data.highestBid === undefined
                    ? "-"
                    : data.highestBid
                }`;
              } else {
                return "-";
              }
            },
            sortable: true,
          },
        ]
      : []),
    ...(isEnglishAuction
      ? [
          {
            name: "Start/End date & time",
            width: "162px",
            selector: (data, index) => {
              return (
                <Typography
                  className={
                    data.status === "live"
                      ? styles.amountSecondValueLive
                      : styles.amountSecondValueUpcoming
                  }
                >
                  <Countdown
                    key={Math.random()}
                    className={styles.bidLive}
                    date={moment(
                      data.status === "live"
                        ? data.endDateAndTime
                        : data.startDateAndTime
                    )
                      .tz(timeZone)
                      .format()}
                    onComplete={() =>
                      !data.finalizing && lotBidEnded(data, index)
                    }
                    renderer={(...args) =>
                      CountdownRenderer(data.finalizing, ...args)
                    }
                  />
                </Typography>
              );
            },
            sortable: false,
          },
          {
            name: `Lead Bid (${eventDetails.currency})`,
            width: "134px",
            selector: (data) => {
              const startDateAndTime = moment(data.startDateAndTime);
              currentTime = moment(currentTime);

              if (startDateAndTime.isAfter(currentTime)) {
                return <span>XXX</span>;
              } else if (
                currentTime.isSameOrAfter(startDateAndTime) &&
                eventDetails.showH1L1Bid === "YES"
              ) {
                return `${data.highestBid === null ? "-" : data.highestBid}`;
              } else {
                return "-";
              }
            },
            sortable: true,
          },
          {
            name: "Status",
            width: "70px",
            selector: (data) => {
              return (
                <MyBidRankComponent
                  myBidStatus={data.myBidStatus}
                  eventType={eventDetails?.eventType}
                  showH1L1Bid={eventDetails?.showH1L1Bid}
                />
              );
            },
            sortable: false,
          },
          {
            name: "Bid Rate",
            width: "150px",
            selector: (data) => {
              let placeholder = "";
              const startDateAndTime = moment(data.startDateAndTime);
              currentTime = moment(currentTime);

              if (startDateAndTime.isSameOrAfter(currentTime)) {
                placeholder = data.acceptedPrebid;
              }

              return (
                <TextField
                  size="small"
                  type="number"
                  variant="outlined"
                  key={data.lotNumber}
                  disabled={data.finalizing}
                  placeholder={placeholder}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step:
                        eventDetails.variableIncrementDecrement === "NO"
                          ? data.increment
                          : 1,
                      value: userAmount[`${data.lotNumber}`] || "",
                      className: styles.auctionAmountField,
                      onChange: ({ target }) => {
                        handleUserAmountChange(
                          data.lotNumber,
                          target.value,
                          userAmount
                        );
                      },
                    },
                  }}
                />
              );
            },
            sortable: true,
          },
        ]
      : []),
    {
      name: "Bid Now",
      selector: (data) =>
        isOnline ? (
          data.myBidStatus.authorized === false ||
          data.auctioneerName == userData.username ||
          userData.role == "ADMIN" ? (
            <Box className={styles.auctionBidErrorBox}>Cannot Bid</Box>
          ) : data.finalizing ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "3rem",
              }}
            >
              <CircularProgress size="1.2rem" />
            </div>
          ) : (
            <Button
              disabled={
                isEnglishAuction ? userAmount[`${data.lotNumber}`] <= 0 : false
              }
              onClick={() =>
                storeHandler(data, userAmount[`${data.lotNumber}`])
              }
              className={
                isEnglishAuction && userAmount[`${data.lotNumber}`] <= 0
                  ? styles.auctionBidErrorBox
                  : styles.auctionBidButton
              }
            >
              Bid Now
            </Button>
          )
        ) : (
          <Box className={styles.auctionBidErrorBox}>
            Network Lost{" "}
            <WifiSVG style={{ marginLeft: "5px", fontSize: "18px" }} />
          </Box>
        ),
      sortable: false,
    },
  ];

  const createdLots =
    lots?.map((lot, index) => ({
      id: index + 1,
      location: getLocationString(lot.event, lot.location),
      acceptedPrebid: lot.acceptedPrebid,
      ...lot,
    })) || [];

  return { columns, data: createdLots };
};

export default createBidListLots;
