import React from "react";
import { Autocomplete, TextField } from "@mui/material";

const SelectOrAddCurrency = ({
  value = null,
  setValue,
  options,
  setOptions,
}) => {
  return (
    <Autocomplete
      name="currency"
      id="currencySelect"
      placeholder="Currency"
      options={options}
      noOptionsText="Enter to create a new option"
      size="small"
      style={{
        background: "#FBFBFB",
      }}
      value={value || ""}
      onInputChange={(e, newValue) => {
        setValue(newValue);
      }}
      defaultValue={value}
      isOptionEqualToValue={(option, value) => {
        return option.value === value;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          value={value}
          onKeyDown={(e) => {
            if (
              e.key === "Enter" &&
              options.findIndex((o) => o.value === value) === -1
            ) {
              setOptions((o) => o.concat({ label: value, value }));
            }
          }}
        />
      )}
    />
  );
};

export default SelectOrAddCurrency;
