import React, { useState } from "react";
import { Avatar, Box, Button } from "@mui/material";
import axios from "axios";
import moment from "moment";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import lodash, { omit } from "lodash";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

// mui imports
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

// component imports
import MountCondition from "../../../Shared/MountCondition";
import routes from "../../../../../constants/routes";
import getEventItemsListAPI from "../../../../../api/getEventItemsListAPI";
import deleteEventItemAPI from "../../../../../api/deleteEventItemAPI";
import useStyles from "./item.style";
import excelImportLots from "../../../../../api/adminAPI/excelImportLots";
import config from "../../../../../config/config";
import Loader from "../../../../../common/Loader";

function ListView(props) {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const eventId = location.state.data.eventId;
  const [eventItemDetails, updateEventItemDetails] = useState({
    apiState: "loading",
    list: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("EXCEL");
  const [selectedTimeInterval, setSelectedTimeInterval] = useState("");

  const [errors, setErrors] = useState({});
  const [auctionBulkUploadDialog, toggleAuctionBulkUploadDialog] =
    useState(false);
  const [uploadRules, setUploadRules] = useState({
    manualStartDate: moment().utc().toISOString(),
    manualEndDate: moment().utc().toISOString(),
    timeInterval: null,
    importType: "EXCEL",
  });

  const EventItemsDetails = async (props) => {
    try {
      const itempayload = { itemId: eventId };
      const { data } = await getEventItemsListAPI(itempayload);
      console.log("EventItemsDetails data", data);
      if (data)
        updateEventItemDetails({ apiState: "success", list: data.data });
      else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    EventItemsDetails();
    return () => {
      return false;
    };
  }, [eventId]);

  const [open, setOpen] = React.useState(false);
  const [deleteEventId, setDeleteEventId] = React.useState("");
  const [deleteEventItemId, setDeleteEventItemId] = React.useState("");

  const handleClickOpen = (eventItemId, eventId) => {
    setOpen(true);
    setDeleteEventId(eventId);
    setDeleteEventItemId(eventItemId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [file, setFile] = useState();

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const closeAuctionBulkUploadDialog = () => {
    setIsLoading(false);
    setFile(null);
    setUploadRules({
      manualStartDate: moment().utc().toISOString(),
      manualEndDate: moment().utc().toISOString(),
      timeInterval: null,
      importType: "EXCEL",
    });

    return toggleAuctionBulkUploadDialog(false);
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const validation = {
        size: 5 * 1024 * 1024,
        extensions: ["xlsx", "xls", "csv"],
      };
      const extension = (fileName) => {
        return fileName.substr(fileName.lastIndexOf(".") + 1);
      };

      if (lodash.isEmpty(event.target.file.files)) {
        return toast.error("Please upload the file first.");
      }
      if (file && file.size > validation.size) {
        return toast.error("File size exceeds the maximum limit of 5MB.");
      }
      if (!validation.extensions.includes(extension(file.name))) {
        return toast.error("Invalid file type");
      }

      const payload = { file: file, importType: uploadRules.importType };
      setIsLoading(true);

      if (uploadRules.importType === "MANUAL") {
        payload.manualStartDate = uploadRules.manualStartDate;
        payload.manualEndDate = uploadRules.manualEndDate;

        if (uploadRules.timeInterval) {
          payload.timeInterval = uploadRules.timeInterval;
        }
      }

      const { data } = await excelImportLots(payload, eventId);

      if (data) {
        toast.success("Lots Imported Successfully!");
        EventItemsDetails();
      }
    } catch (error) {
      toast.error(
        axios.isAxiosError(error)
          ? error.response.data.error
          : "Something went wrong."
      );
    } finally {
      return closeAuctionBulkUploadDialog();
    }
  };

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
    setUploadRules({ ...uploadRules, importType: event.target.value });
  };

  const handleTimeChange = (event) => {
    setSelectedTimeInterval(event.target.value);
    setUploadRules({ ...uploadRules, timeInterval: event.target.value });
  };

  const validate = (name, value) => {
    //A function to validate each input values
    switch (name) {
      case "manualStartDate":
        if (value === null) {
          // we will set the error publishDateTime

          setErrors({
            ...errors,
            manualStartDate: "Start Date Time Can Not Be Empty.",
          });
        } else {
          // set the error publishDateTime empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "manualStartDate");
          setErrors(newObj);
        }
        break;

      case "manualEndDate":
        if (value === null) {
          // we will set the error bidStartDateTime

          setErrors({
            ...errors,
            manualEndDate: "End Start Date Time Can Not Be Empty.",
          });
        } else {
          // set the error bidStartDateTime empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "manualEndDate");
          setErrors(newObj);
        }
        break;

      case "timeInterval":
        if (value === null) {
          // we will set the error bidStartDateTime

          setErrors({
            ...errors,
            manualEndDate: "Time Interval Can Not Be Empty.",
          });
        } else {
          // set the error bidStartDateTime empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "timeInterval");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  };

  const dateHandler = (name, date) => {
    validate(name, date);
    if (lodash.isEmpty(date)) {
      return true;
    }

    if (date.$d == "Invalid Date") {
      return true;
    }

    const dateUTC = moment.utc(date.$d).toISOString();

    setUploadRules((prevUploadRules) => ({
      ...prevUploadRules,
      [name]: dateUTC,
    }));
  };

  const handleDownload = () => {
    window.location.href = `${config.api.base_url}events/${eventId}/download-item-file`;
  };

  const updateDeleteEventItem = async () => {
    try {
      await deleteEventItemAPI({
        eventItemId: deleteEventItemId,
        eventId: deleteEventId,
      });

      toast.success("Event Item Deleted Successfully!");
      EventItemsDetails();
    } catch (error) {
      toast.error(
        axios.isAxiosError(error)
          ? error.response.data.error
          : "Something went wrong."
      );
    } finally {
      return handleClose();
    }
  };

  return (
    <>
      {isLoading === true && <Loader isLoading={isLoading} />}
      <div className={classes.listView}>
        <div className={classes.btnSection}>
          <Button
            className={classes.AddBtn}
            onClick={() => {
              toggleAuctionBulkUploadDialog(true);
            }}
          >
            <AddIcon />
            Bulk Upload
          </Button>
          <Button
            className={classes.AddBtn}
            onClick={() => {
              props.setHide(true);
            }}
            sx={{ marginLeft: "1em" }}
          >
            <AddIcon />
            Add More Lots
          </Button>
        </div>

        <div
          className="modal show"
          style={{ display: "block", position: "initial" }}
        >
          <Modal
            show={auctionBulkUploadDialog}
            onHide={() => {
              closeAuctionBulkUploadDialog();
            }}
            className="editListModal"
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>Bulk Upload</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-3 bg-x">
                  <Form.Label>Select Time Type</Form.Label>
                  <Form.Select
                    value={uploadRules.importType}
                    onChange={handleDropdownChange}
                    className="editDropdown"
                  >
                    <option value="EXCEL">Excel</option>
                    <option value="MANUAL">Manual</option>
                  </Form.Select>
                </div>
                <MountCondition condition={uploadRules.importType === "MANUAL"}>
                  <MountCondition.True>
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-4 dateSelector">
                          <Form.Label>Select Start Date and Time</Form.Label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              className="datePicker newDatePicker"
                              renderInput={(props) => (
                                <TextField
                                  {...props}
                                  className="form-control form-control-sm p-0 m-0"
                                />
                              )}
                              value={uploadRules.manualStartDate}
                              onChange={(date) => {
                                dateHandler("manualStartDate", date);
                              }}
                              minDate={moment().toDate()}
                              style={{ padding: "7px 14px !important" }}
                            />
                          </LocalizationProvider>
                        </div>

                        <div className="col-md-4 dateSelector">
                          <Form.Label>Select End Date and Time</Form.Label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              className="datePicker newDatePicker"
                              renderInput={(props) => (
                                <TextField
                                  {...props}
                                  className="form-control form-control-sm p-0 m-0"
                                />
                              )}
                              value={uploadRules.manualEndDate}
                              onChange={(date) => {
                                dateHandler("manualEndDate", date);
                              }}
                              minDate={moment().toDate()}
                              style={{ padding: "7px 14px !important" }}
                            />
                          </LocalizationProvider>
                        </div>

                        <div className="col-md-4">
                          <Form.Label>Select Interval</Form.Label>
                          <Form.Select
                            onChange={handleTimeChange}
                            className="editDropdown"
                          >
                            <option value="">Select</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="60">60</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </MountCondition.True>
                </MountCondition>
              </div>

              <form onSubmit={handleSubmit} className="modalInsideForm mt-4">
                <div className="bg-secondary new-background">
                  <input
                    type="file"
                    name="file"
                    onChange={handleChange}
                    accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  />
                </div>
                <div className="modalUpload mt-3">
                  <button
                    type="button"
                    value="Download"
                    className="editUploadButton mx-3"
                    onClick={handleDownload}
                  >
                    Download
                  </button>
                  <button type="submit" className="editUploadButton">
                    Upload
                  </button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </div>

        <div className={classes.table}>
          <Table striped bordered hover responsive className="tableHead">
            <thead>
              <tr>
                <th>Action</th>
                <th>Sr.</th>
                <th>Lot Number</th>
                <th>Short Description</th>
                <th>Location</th>
                <th>Qty</th>
                <th>unit</th>
                <th>Start Price</th>
                <th>
                  {" "}
                  {props.eventDetail.eventType === "FORWARD_AUCTION"
                    ? "Increment"
                    : "Decrement"}
                </th>
                <th>Start date & Time</th>
                <th>
                  {props.eventDetail.eventFormat === "ENGLISH_AUCTION"
                    ? "End date & Time"
                    : "Time interval"}
                </th>
                <th>Remark</th>
                <th>Images</th>
              </tr>
            </thead>
            <tbody>
              {eventItemDetails.list.length > 0 ? (
                eventItemDetails.list.map((eventItem, index) => (
                  <tr>
                    <td>
                      <div className={classes.editBtn}>
                        {/* <Button sx={{ padding: "0" }}>
                    <EditIcon className={classes.edit} />
                  </Button> */}
                        <Button
                          onClick={() =>
                            handleClickOpen(
                              eventItem.eventItemId,
                              eventItem.eventId
                            )
                          }
                        >
                          <DeleteIcon className={classes.trash} />
                        </Button>
                      </div>
                    </td>
                    <td>{++index}</td>
                    <td>{eventItem.lotNumber}</td>
                    <td>{eventItem.shortDescription}</td>
                    <td
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span>
                        {props.eventDetail.province}, {props.eventDetail.city},
                      </span>
                      <span>{props.eventDetail.country}</span>
                    </td>
                    <td>{eventItem.quantity}</td>
                    <td>{eventItem.unit}</td>
                    <td>
                      {eventItem.startPrice} ({eventItem.currency})
                    </td>
                    <td>
                      {eventItem.increment} ({eventItem.currency})
                    </td>
                    <td style={{ width: "200px !important" }}>
                      {moment(eventItem.startDateAndTime).format(
                        "DD-MM-YYYY hh:mm:ss"
                      )}
                    </td>
                    <td style={{ width: "200px !important" }}>
                      {props.eventDetail.eventFormat === "ENGLISH_AUCTION"
                        ? moment(eventItem.endDateAndTime).format(
                            "DD-MM-YYYY hh:mm:ss"
                          )
                        : `${eventItem.timeInterval} min.`}
                    </td>
                    <td>Remarks</td>
                    <td>
                      <Avatar src={eventItem.eventItemImagesUrl[0]} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  {" "}
                  <td colspan="14">
                    <center>
                      <b>Lots Are Not Available</b>
                    </center>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Event Item?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete Item ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={updateDeleteEventItem} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Box className={classes.saveNextBtns}>
          <Button
            className={classes.pre}
            style={{ width: "100%" }}
            onClick={() => {
              props.stepper.a11yProps(0);
            }}
          >
            Previous
          </Button>
          <Button
            className={classes.next}
            style={{ width: "100%" }}
            onClick={() => {
              props.stepper.a11yProps(2);
            }}
            sx={{ marginLeft: "1em" }}
          >
            Next
          </Button>
          <Button
            className={classes.next}
            style={{ width: "100%" }}
            sx={{ marginLeft: "1em" }}
            onClick={() => {
              navigate(routes.dashboard);
            }}
          >
            Go To Dashboard
          </Button>
        </Box>
      </div>
    </>
  );
}

export default ListView;
