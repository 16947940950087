import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Modal } from "@mui/material";
import useStyles from "../../../../../Create/Event/ViewBuyerLots/ViewLots/ViewLots.style";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import EditLotsTable from "../../../../../../commonComponents/EditLotsTable";
import { RiInboxArchiveLine } from "react-icons/ri";
import getViewByLotsList from "../../../../../../../api/getViewByLotsList";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import routes from "../../../../../../../constants/routes";
import CloseIcon from "@mui/icons-material/Close";

export default function EditViewLots(eventEditId) {
  const classes = useStyles();
  const eventId = eventEditId.eventEditId.eventEditId;
  const navigate = useNavigate();
  //   const location = useLocation();
  //   const eventId = location.state.data.eventId;
  const [tableEventsItemData, setTableEventsItemData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, updateClearSelectedRows] = useState(false);

  const [columnsEventBuyers, setColumnsEventBuyers] = useState([
    {
      name: "Sr",
      selector: "id",
      cell: (data) => String(data.id),
      sortable: true,
    },
    {
      name: "Lot No.",
      selector: "lotNumber",
      cell: (data) => String(data.lotNumber),
      sortable: true,
    },
    {
      name: "Short Description",
      selector: "shortDescription",
      cell: (data) => String(data.shortDescription),
      sortable: true,
    },
    {
      name: "Location",
      selector: "location",
      cell: (data) => String(data.location),
      sortable: true,
    },
    {
      name: "Qty & Unit",
      selector: "QtyUnit",
      cell: (data) => String(data.QtyUnit),
      sortable: true,
    },
    {
      name: "Start Date & Time",
      selector: "startDate",
      cell: (data) => String(data.startDate),
      sortable: true,
    },
    {
      name: "End Date & Time",
      selector: "EndDate",
      cell: (data) => String(data.EndDate),
      sortable: true,
    },
    {
      name: "Buyers",
      cell: (data) => (
        <div>
          <RiInboxArchiveLine className={classes.lots} />{" "}
          {String(data.totalBuyers)}
        </div>
      ),
      sortable: false,
      export: false,
    },
  ]);

  const eventItemsList = async () => {
    try {
      const payload = { auctionId: eventId };

      const dataEventItems = await getViewByLotsList(payload);

      if (dataEventItems) {
        let id = 1;
        const eventsBuyerList = dataEventItems.data.map((data) => {
          return {
            id: id++,
            eventItemId: data.eventItemId,
            lotNumber: data.lotNumber,
            location: data.location,
            shortDescription: data.shortDescription,
            QtyUnit: `${data.quantity} ${data.unit}`,
            startDate: moment(data.startDateAndTime).format(
              "DD-MM-YYYY hh:mm:ss"
            ),
            EndDate: moment(data.endDateAndTime).format("DD-MM-YYYY hh:mm:ss"),
            totalBuyers: data.totalBuyers,
          };
        });

        setTableEventsItemData(eventsBuyerList);
      } else throw new Error(dataEventItems);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    eventItemsList();
    return () => {
      return false;
    };
  }, [eventId]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    //   border: "1px solid #989898",
    boxShadow: "none",
    p: 4,
  };

  const [modalOpen, setModalOpen] = useState({});
  const handleOpen = (text) =>
    setModalOpen({
      visible: true,
      type: text,
    });
  const handleClose = (text) =>
    setModalOpen({
      visible: false,
      type: text,
    });

  return (
    <div className="editviewBuyer">
      <Box sx={{ width: "100%", padding: "0px !important" }}>
        {selectedRows.length > 0 && (
          <div
            className="email-container"
            style={{
              background: "#2D55EB",
              paddingTop: "10px",
              height: "55px",
            }}
          >
            <Button
              type="primary"
              className="addlots"
              onClick={() => {
                handleOpen("Add");
              }}
            >
              Add Buyer
            </Button>
            <Button
              type="primary"
              className="addlots"
              sx={{ color: "red !important" }}
              onClick={() => {
                handleOpen("Remove");
              }}
            >
              Remove Buyer
            </Button>
          </div>
        )}
        <DataTableExtensions
          print={false}
          export={false}
          exportHeaders={false}
          columns={columnsEventBuyers}
          data={tableEventsItemData}
        >
          <DataTable
            noHeader
            defaultSortField="id"
            defaultSortAsc={true}
            pagination
            highlightOnHover
            export={false}
            exportHeaders={false}
            selectableRows={true}
            onSelectedRowsChange={(data) => {
              updateClearSelectedRows(false);
              setSelectedRows(data.selectedRows);
            }}
            //clearSelectedRows={clearSelectedRows}
            className="DataTable"
          />
        </DataTableExtensions>
        <Button
          className={classes.next}
          onClick={() => {
            navigate(routes.dashboard);
          }}
        >
          Go To Dashboard
        </Button>
        <Modal
          open={modalOpen.visible}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ textAlign: "right" }}>
              <Button
                className="hoverClose"
                onClick={handleClose}
                sx={{
                  backgroundColor: "rgb(45, 85, 235)",
                  color: "#fff",
                  marginBottom: "1em",
                }}
              >
                <CloseIcon />
              </Button>
            </Box>
            <EditLotsTable
              className="ronakssssss"
              type={modalOpen.type}
              modalHandleClose={handleClose}
              eventItemsList={eventItemsList}
              data={[...selectedRows]}
              eventId={eventId}
            />
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
