import axios from "axios";
import config from "../../config/config";
import { getToken } from "../../common";

export const updateEventRulesAPI = async (params, eventId) => {
  return await axios({
    method: "patch",
    url: `${config.api.base_url}dashboard/events/${eventId}`,
    data: params,
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: getToken(),
    },
  });
};

export default updateEventRulesAPI;
