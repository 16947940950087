import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import useStyles from "../dashAuction.style";
import eventListAPI from "../../../../api/adminAPI/eventListAPI";
import eventDeleteAPI from "../../../../api/adminAPI/eventDeleteAPI";
// import { columns, data } from "./Data.js";
import { useState, useEffect } from "react";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getToken,
  getUserData,
  getTimeZone,
  localTimeZone,
  localSetTimeZone,
} from "../../../../common";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import lodash from "lodash";
import { FaRegFileExcel } from "react-icons/fa";
import * as XLSX from "xlsx";

function AuctionTableBuyer({ onView, onEdit }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [eventId, setEventId] = React.useState("");

  const getZone = getTimeZone() ? getTimeZone() : localTimeZone();

  const handleClick = (event, eventId) => {
    setEventId(eventId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setEventId("");
  };

  const [tableEventsData, setTableEventsData] = useState([]);

  const getEventFormatText = (eventFormat) => {
    switch (eventFormat) {
      case "DUTCH_AUCTION":
        return "Dutch";
      case "JAPANESE_AUCTION":
        return "Japanese";
      default:
        return "English";
    }
  };

  const eventsList = async () => {
    try {
      const dataEvent = await eventListAPI();

      if (dataEvent) {
        let id = 1;
        const eventsBuyerList = dataEvent.data.map((data, index) => {
          return {
            id: id++,
            sellerName: data.sellerName,
            eventId: data.eventId,
            auctionTitle: data.auctionTitle,
            eventType:
              data.eventType === "FORWARD_AUCTION" ? "FORWARD" : "REVERSE",
            eventFormat: getEventFormatText(data.eventFormat) || "English",
            auctionId: data.auctionId,
            ownerOfLots: data.ownerOfLots,
            bidedLots: data.bidedLots,
            location: data.location,
            startDate: moment(data.startDate)
              .tz(getZone)
              .format("DD/MM/YYYY hh:mm:ss"),
            endDate:
              data.eventFormat === "ENGLISH_AUCTION"
                ? moment(data.endDate).tz(getZone).format("DD-MM-YYYY hh:mm:ss")
                : "-",
          };
        });

        setTableEventsData(eventsBuyerList);
      } else throw new Error(dataEvent);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    eventsList();
    return () => {
      return false;
    };
  }, []);

  // console.clear()
  // console.log(tableEventsData)

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const columnsEventList = [
    {
      name: "Sr.",
      //selector: "id",
      cell: (data) => String(data.id),
      // sortable: true,
    },
    {
      name: "Seller",
      selector: "sellerName",
      cell: (data) => String(data.sellerName),
      sortable: true,
    },
    {
      name: "Auction ID",
      selector: "auctionId",
      cell: (data) => String(data.auctionId),
      sortable: true,
    },
    {
      name: "Auction Title",
      selector: "auctionTitle",
      cell: (data) => {
        return (
          <Tooltip
            title={data.auctionTitle}
            placement="top-start"
            TransitionComponent={Zoom}
          >
            <span className={classes.ellipsisText}>{data.auctionTitle}</span>
          </Tooltip>
        );
      },
      sortable: true,
    },
    {
      name: "Event Type",
      selector: "eventType",
      cell: (data) => String(data.eventType),
      sortable: true,
    },
    {
      name: "Event Format",
      selector: "eventFormat",
      cell: (data) => String(data.eventFormat),
      sortable: true,
    },
    {
      name: "Owner of Lots",
      selector: "ownerOfLots",
      cell: (data) => {
        return (
          <Tooltip
            title={data.ownerOfLots}
            placement="top-start"
            TransitionComponent={Zoom}
          >
            <span className={classes.ellipsisText}>{data.ownerOfLots}</span>
          </Tooltip>
        );
      },
      sortable: true,
    },
    {
      name: "Start Date & Time",
      selector: "startDate",
      cell: (data) => String(data.startDate),
      sortable: true,
    },
    {
      name: "End Date & Time",
      selector: "endDate",
      cell: (data) => (data.endDate ? String(data.endDate) : "-"),
      sortable: true,
    },
    {
      name: "Bidded Lots",
      selector: "bidedLots",
      cell: (data) => data.bidedLots,
      sortable: true,
    },
    {
      name: "Location",
      selector: "location",
      cell: (data) => String(data.location),
      sortable: true,
    },
    {
      name: "Action",
      //selector: "action",
      // sortable: true,
      cell: (data) => (
        <div>
          {" "}
          <Button
            aria-describedby={id}
            variant="contained"
            //onClick={handleClick}
            onClick={(event) => {
              handleClick(event, data.eventId);
            }}
          >
            <MoreVertIcon />
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography sx={{ p: 2, display: "grid" }}>
              <div
                className={classes.viewBtn}
                onClick={() => {
                  onView(eventId);
                }}
              >
                <RemoveRedEyeIcon
                  style={{ color: "#2D55EB", paddingRight: "5px" }}
                />
                Report
              </div>
              {/* <span className={classes.viewBtn} onClick={() => {
                          onEdit(eventId);
                        }}>
                <EditIcon style={{ color: "#2D55EB", paddingRight: "5px" }} />
                Edit
              </span>
              <span className={classes.viewBtn} onClick={() => handleClickOpen(eventId)}>
                <DeleteIcon style={{ color: "red", paddingRight: "5px" }}  />
                Delete
              </span>              */}
            </Typography>
          </Popover>
        </div>
      ),
    },
  ];

  const [openDilogue, setOpenDilogue] = React.useState(false);

  const [deleteEventId, setDeleteEventId] = React.useState("");

  const updateDeleteEvent = async () => {
    try {
      const { data } = await eventDeleteAPI(deleteEventId);

      toast("Event Deleted Successfully!");
      setTimeout(() => {
        eventsList();
        setOpenDilogue(false);
        setAnchorEl(null);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpen = (eventId) => {
    setOpenDilogue(true);
    setDeleteEventId(eventId);
  };

  const handleCloseDilogue = () => {
    setOpenDilogue(false);
    setAnchorEl(null);
  };

  const exportToXLSX = (tableData, tableHeader) => {
    const headerColumns = lodash.map(tableHeader, ({ name }) => {
      if (name != "Action") {
        return name;
      }
    });
    const dataColumns = lodash.map(tableData, (columns) => [
      columns.id,
      columns.sellerName,
      columns.auctionId,
      columns.auctionTitle,
      columns.eventType,
      columns.ownerOfLots,
      columns.startDate,
      columns.endDate,
      parseFloat(Number(columns.bidedLots)),
      columns.location,
    ]);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headerColumns, ...dataColumns]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "auctions-listing.xlsx");
  };

  const ExportButton = () => (
    <Button onClick={() => exportToXLSX(tableEventsData, columnsEventList)}>
      <FaRegFileExcel
        style={{ fontSize: "30px", color: "#04aa18", paddingTop: "4px" }}
      />
    </Button>
  );

  return (
    <div className="AuctionTable" source="BUYER">
      <h3>Auctions Listing</h3>

      <DataTableExtensions
        print={false}
        export={false}
        exportHeaders={false}
        columns={columnsEventList}
        data={tableEventsData}
      >
        <DataTable
          noHeader={false}
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          highlightOnHover
          className="DataTable"
          actions={<ExportButton />}
        />
      </DataTableExtensions>

      <Dialog
        open={openDilogue}
        onClose={handleCloseDilogue}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Event ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete Event ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDilogue}>Cancle</Button>
          <Button onClick={updateDeleteEvent} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AuctionTableBuyer;
