import React, { useState, useEffect } from "react";

// mui imports
import { Typography, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import DescriptionIcon from "@mui/icons-material/Description";

// APIs
import getCountries from "../../../../api/getContriesAPI";
import getStateAPI from "../../../../api/getStateAPI";

// component imports
import colorSchemes from "../../../../constants/colorSchemes";
import Loader from "../../../../common/Loader";
import useStyles from "./createEvent.styles";

import BulkUploadDialog from "../../Shared/BulkUploadDialog";
import AuctionTypeSelectorDropdown from "./AuctionTypeSelectorDropdown.js";
import RenderCreateAuctionForm from "./RenderCreateAuctionForm.js";
import { useNavigate, useSearchParams } from "react-router-dom";

function CreateEvent() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isLargeScreen = useMediaQuery("(min-width:768px)");

  const [isLoading, setIsLoading] = useState(false);
  const [createType, setCreateType] = useState(null);
  const [auctionBulkUploadDialog, toggleAuctionBulkUploadDialog] =
    useState(false);

  //Get Countries Nad States
  const [countriesList, updateCountriesList] = useState({
    apiState: "loading",
    list: [],
  });

  const [stateList, updateStateList] = useState({
    apiState: "loading",
    list: [],
  });

  const fetchCountryList = async () => {
    try {
      const { data } = await getCountries();

      if (data) {
        if (data) updateCountriesList({ apiState: "success", list: data });
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStateData = async (countryId) => {
    try {
      console.log(countryId);
      const { data } = await getStateAPI(countryId);

      if (data) {
        if (data) updateStateList({ apiState: "success", list: data });
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };

  const renderCreateAuctionType = (type) => {
    switch (type) {
      case "english_auction":
        return "english auction";
      case "dutch_auction":
        return "dutch auction";
      case "japanese_auction":
        return "japanese auction";
      default:
        return "auction";
    }
  };

  useEffect(() => {
    fetchCountryList();
    fetchStateData();
  }, []);

  const handleSelectedCreateType = (option) => {
    setCreateType(option);

    // Update query params
    const params = new URLSearchParams(searchParams);
    params.set("auction-type", option);
    navigate({ search: params.toString() });
  };

  return (
    <>
      {isLoading === true && <Loader isLoading={isLoading} />}
      <BulkUploadDialog
        show={auctionBulkUploadDialog}
        title="Create auction using excel file"
        toggleAuctionBulkUploadDialog={toggleAuctionBulkUploadDialog}
        setIsLoading={setIsLoading}
        setCreateType={setCreateType}
      />
      <div className={classes.event}>
        <Typography
          className={classes.textHead}
          sx={{
            textAlign: "center",
            padding: "1em",
            fontSize: "20px",
            fontWeight: "600",
            color: colorSchemes.primaryColor,
            marginTop: isLargeScreen ? "2em" : "8em",
          }}
        >
          Create {renderCreateAuctionType(createType)}
        </Typography>
        {createType === null && (
          <div className="container">
            <div className="section-event mb-5">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  className={classes.upload}
                  sx={{
                    display: "block",
                    textAlign: "right",
                  }}
                  onClick={() => {
                    setCreateType("excel");
                    // open excel dialog
                    toggleAuctionBulkUploadDialog(true);
                  }}
                  size="small"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textTransform: "none",
                    width: "16rem",
                    marginBottom: ".6rem",
                  }}
                >
                  <DescriptionIcon
                    className={classes.uploadIcon}
                    style={{
                      marginRight: "8px",
                      fontSize: "22px",
                    }}
                  />
                  <span className="text-black normal-case">
                    Create auction using excel file
                  </span>
                </Button>
                <AuctionTypeSelectorDropdown
                  onSelect={handleSelectedCreateType}
                />
              </div>
            </div>
          </div>
        )}
        {createType && createType !== "excel" && (
          <RenderCreateAuctionForm
            stateList={stateList}
            countriesList={countriesList}
            setIsLoading={setIsLoading}
            classes={classes}
            fetchStateData={fetchStateData}
            eventFormat={createType}
          />
        )}
      </div>
    </>
  );
}

export default CreateEvent;
