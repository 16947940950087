import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useStyles from "../dashAuction.style";
import eventListAPI from "../../../../api/adminAPI/eventListAPI";
import eventListBySellerAPI from "../../../../api/adminAPI/eventListBySellerAPI";
import eventDeleteAPI from "../../../../api/adminAPI/eventDeleteAPI";
// import { columns, data } from "./Data.js";
import lodash from "lodash";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getTimeZone, localTimeZone } from "../../../../common";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { FaRegFileExcel } from "react-icons/fa";
import * as XLSX from "xlsx";

function AuctionTable({ onView, onEdit, buyerId }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [eventId, setEventId] = useState("");
  const [tableEventsData, setTableEventsData] = useState([]);
  const [popoverDetails, setPopoverDetails] = useState(null);

  useEffect(() => {
    eventsList();
  }, []);

  const getZone = getTimeZone() ? getTimeZone() : localTimeZone();

  const handleClick = (event, eventId, id) => {
    setPopoverDetails(tableEventsData[id - 1]);
    setEventId(eventId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setEventId("");
    setPopoverDetails(null);
  };

  const getEventFormatText = (eventFormat) => {
    switch (eventFormat) {
      case "DUTCH_AUCTION":
        return "Dutch";
      case "JAPANESE_AUCTION":
        return "Japanese";
      default:
        return "English";
    }
  };

  const eventsList = async () => {
    try {
      let dataEvent = "";
      if (buyerId === undefined) {
        dataEvent = await eventListAPI();
      } else {
        const payload = { userId: buyerId };
        dataEvent = await eventListBySellerAPI(payload);
      }

      if (dataEvent) {
        const currentDate = moment();
        let id = 1;
        const eventsBuyerList = dataEvent.data.map((data, _index) => {
          const auctionEndDate = moment(data.bidEndDateTime).add(48, "hours"); // auction end date + 48 hours
          const auctionEnded = auctionEndDate.isBefore(currentDate);

          return {
            id: id++,
            auctioneerName: data.auctioneerName,
            auctionStatus: data.auctionStatus,
            eventId: data.eventId,
            eventNumber: data.eventNumber,
            auctionTitle: data.auctionTitle,
            eventType:
              data.eventType === "FORWARD_AUCTION" ? "FORWARD" : "REVERSE",
            eventFormat: getEventFormatText(data.eventFormat) || "ENGLISH",
            totalLots: data.totalLots,
            totalBuyers: data.totalBuyers,
            totalAllotedLots: data.totalAllotedLots,
            totalBids: data.totalBids,
            ownerOfLots: data.ownerOfLots,
            location: `${data.country} ${data.province}`,
            bidStartDateTime: moment(data.bidStartDateTime)
              .tz(getZone)
              .format("DD/MM/YYYY hh:mm:ss"),
            bidEndDateTime:
              data.eventFormat === "ENGLISH_AUCTION"
                ? moment(data.bidEndDateTime)
                    .tz(getZone)
                    .format("DD-MM-YYYY hh:mm:ss")
                : "-",
            auctionEnded,
          };
        });

        setTableEventsData(eventsBuyerList);
      } else throw new Error(dataEvent);
    } catch (error) {
      console.log(error);
    }
  };

  const open = Boolean(anchorEl);
  const columnsEventList = [
    {
      name: "Sr.",
      //selector: "id",
      cell: (data) => String(data.id),
      // sortable: true,
    },
    {
      name: "Seller",
      selector: "auctioneerName",
      cell: (data) => {
        return (
          <Tooltip
            title={data.auctioneerName}
            placement="top-start"
            TransitionComponent={Zoom}
          >
            <span className={classes.ellipsisText}>{data.auctioneerName}</span>
          </Tooltip>
        );
      },
      sortable: true,
    },
    {
      name: "Status",
      selector: "auctionStatus",
      width: "8%",
      cell: (data) => String(data.auctionStatus),
      sortable: true,
    },
    {
      name: "Auction ID",
      selector: "eventNumber",
      cell: (data) => String(data.eventNumber),
      sortable: true,
    },
    {
      name: "Event Name",
      selector: "auctionTitle",
      cell: (data) => {
        return (
          <Tooltip
            title={data.auctionTitle}
            placement="top-start"
            TransitionComponent={Zoom}
          >
            <span className={classes.ellipsisText}>{data.auctionTitle}</span>
          </Tooltip>
        );
      },
      sortable: true,
    },
    {
      name: "Event Format",
      selector: "eventFormat",
      cell: (data) => String(data.eventFormat),
      sortable: true,
    },
    {
      name: "Event Type",
      selector: "eventType",
      cell: (data) => String(data.eventType),
      sortable: true,
    },
    {
      name: "Start Date & Time",
      selector: "bidStartDateTime",
      cell: (data) => String(data.bidStartDateTime),
      sortable: true,
    },
    {
      name: "End Date & Time",
      selector: "bidEndDateTime",
      cell: (data) => {
        return data.bidEndDateTime ? String(data.bidEndDateTime) : "-";
      },
      sortable: true,
    },
    {
      name: "No. of Lots",
      selector: "totalLots",
      cell: (data) => data.totalLots,
      sortable: true,
    },
    {
      name: "Lot Owner",
      selector: "ownerOfLots",
      cell: (data) => {
        return (
          <Tooltip
            title={data.ownerOfLots}
            placement="top-start"
            TransitionComponent={Zoom}
          >
            <span className={classes.ellipsisText}>{data.ownerOfLots}</span>
          </Tooltip>
        );
      },
      sortable: true,
    },
    {
      name: "No. of Buyer",
      selector: "totalBuyers",
      cell: (data) => data.totalBuyers,
      sortable: true,
    },
    {
      name: "Alloted Lots",
      selector: "totalAllotedLots",
      cell: (data) => data.totalAllotedLots,
      sortable: true,
    },
    {
      name: "Bidded Lots",
      selector: "totalBids",
      cell: (data) => data.totalBids,
      sortable: true,
    },
    {
      name: "Location",
      selector: "location",
      cell: (data) => String(data.location),
      sortable: true,
    },
    {
      name: "Action",
      // selector: "action",
      // sortable: true,
      cell: (data) => {
        return (
          <div>
            <Button
              variant="contained"
              //onClick={handleClick}
              onClick={(event) => {
                handleClick(event, data.eventId, data.id);
              }}
            >
              <MoreVertIcon />
            </Button>
          </div>
        );
      },
    },
  ];

  const [openDilogue, setOpenDilogue] = React.useState(false);

  const [deleteEventId, setDeleteEventId] = React.useState("");

  const updateDeleteEvent = async () => {
    try {
      const { data } = await eventDeleteAPI(deleteEventId);

      toast("Event Deleted Successfully!");
      setTimeout(() => {
        eventsList();
        setOpenDilogue(false);
        setAnchorEl(null);
      }, 1000);
    } catch (error) {
      toast.error("Live event cannot be deleted");
      console.log(error);
    }
  };

  const handleClickOpen = (eventId) => {
    setOpenDilogue(true);
    setDeleteEventId(eventId);
  };

  const handleCloseDilogue = () => {
    setOpenDilogue(false);
    setAnchorEl(null);
  };

  const exportToXLSX = (tableData, tableHeader) => {
    const headerColumns = lodash.map(tableHeader, ({ name }) => {
      if (name != "Action") {
        return name;
      }
    });
    const dataColumns = lodash.map(tableData, (columns) => [
      columns.id,
      columns.auctioneerName,
      columns.auctionStatus,
      columns.eventNumber,
      columns.auctionTitle,
      columns.eventType,
      columns.eventFormat,
      columns.bidStartDateTime,
      columns.bidEndDateTime,
      columns.totalLots,
      columns.ownerOfLots,
      columns.totalBuyers,
      columns.totalAllotedLots,
      columns.totalBids,
      columns.location,
    ]);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headerColumns, ...dataColumns]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "auctions-listing.xlsx");
  };

  const ExportButton = () => (
    <Button onClick={() => exportToXLSX(tableEventsData, columnsEventList)}>
      <FaRegFileExcel
        style={{ fontSize: "30px", color: "#04aa18", paddingTop: "4px" }}
      />
    </Button>
  );

  return (
    <div className="AuctionTable" source="ADMIN||SELLER">
      <h3>Auctions Listing</h3>
      <Popover
        id={`${popoverDetails?.eventId}`}
        key={`${popoverDetails?.eventId}`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2, display: "grid" }}>
          <div
            className={classes.viewBtn}
            onClick={() => {
              onView(popoverDetails?.eventId);
            }}
          >
            <RemoveRedEyeIcon
              style={{ color: "#2D55EB", paddingRight: "5px" }}
            />
            Report
          </div>
          <span
            className={classes.viewBtn}
            onClick={() => {
              !popoverDetails?.auctionEnded && onEdit(eventId);
            }}
            style={{
              color: popoverDetails?.auctionEnded ? "grey" : "",
            }}
          >
            <EditIcon
              style={{
                color: popoverDetails?.auctionEnded ? "grey" : "#2D55EB",
                paddingRight: "5px",
              }}
            />
            Edit
          </span>
          <span
            className={classes.viewBtn}
            onClick={() => handleClickOpen(eventId)}
          >
            <DeleteIcon style={{ color: "red", paddingRight: "5px" }} />
            Delete
          </span>
        </Typography>
      </Popover>

      <DataTableExtensions
        print={false}
        export={false}
        exportHeaders={false}
        columns={columnsEventList}
        data={tableEventsData}
      >
        <DataTable
          noHeader={false}
          defaultSortField="id"
          defaultSortAsc={false}
          pagination
          highlightOnHover
          className="DataTable-icon"
          columns={columnsEventList}
          data={tableEventsData}
          actions={<ExportButton />}
        />
      </DataTableExtensions>

      <Dialog
        open={openDilogue}
        onClose={handleCloseDilogue}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Event ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete Event ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDilogue}>Cancle</Button>
          <Button onClick={updateDeleteEvent} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AuctionTable;
